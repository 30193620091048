import * as React from 'react'
import { Container, createStyles, makeStyles, Theme, Typography, Grid } from '@material-ui/core'
import IndexLayout from '../templates/IndexLayout'
import ContactForm from '../components/ContactForm'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: '2rem'
    },
    subHeader: {
      fontSize: '1.2rem'
    }
  })
)

export default function Contact() {
  const classes = useStyles()
  return (
    <IndexLayout title="Contact Us" description="Get in touch with the team at Zeti">
      <div style={{ height: '10vh' }} />
      <Container maxWidth="md">
        <Grid container justifyContent="center" spacing={5}>
          <Grid item sm={6}>
            <Typography className={classes.header} variant="h6">
              Get in Touch
            </Typography>
            <Typography className={classes.subHeader} variant="body1">
              If you’re a fleet operator, financier, investor, wish to become a supplier partner or are just interested in the future of
              mobility then we’d love to hear from you. Please enter your details here and we’ll be in contact.
            </Typography>
            <Typography className={classes.subHeader} variant="body1">
              <br />
              For media enquiries, please email <a href="mailto:press@zeti.co.uk">press@zeti.co.uk</a>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
    </IndexLayout>
  )
}
