import React, { useState } from 'react'
import { createStyles, makeStyles, Theme, Box, TextField, Grid, Button, Typography, Checkbox, FormControlLabel } from '@material-ui/core'
import { useGoal } from 'gatsby-plugin-fathom'
import HCaptcha from '@hcaptcha/react-hcaptcha'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: '2rem'
    },
    subHeader: {
      fontSize: '1.2rem'
    },
    textField: {
      width: '100%',
      color: theme.palette.text.primary,
      // Couldn't work out how to use the theme here, revisit this later
      fontFamily: 'Poppins'
    },
    form: {},
    captchaBox: {
      marginTop: '1rem'
    },
    submitButton: {
      marginTop: '1rem',
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      textTransform: 'none'
    },
    submitted: {
      paddingTop: '75px',
      paddingLeft: '25px',
      fontSize: '1.1rem'
    },
    mobilePhoneField: {
      width: '100%',
      color: theme.palette.text.primary,
      fontFamily: 'Poppins',
      marginBottom: '0px'
    },
    contactNumberFooter: {
      fontStyle: 'italic'
    }
  })
)

export default function Contact() {
  const [contactEmail, setContactEmail] = useState('')
  const [contactName, setContactName] = useState('')
  const [message, setMessage] = useState('')
  const [mobilePhone, setMobilePhone] = useState('')
  const [agreedToMailingList, setAgreedToMailingList] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState<boolean>(false)
  const [hcaptchaToken, setHcaptchaToken] = useState<string>('')
  const [hcaptchaEkey, setHcaptchaEkey] = useState<string>('')
  const classes = useStyles()
  const handleGoal = useGoal('QL9X6OQ9')
  const handleTypingNameGoal = useGoal('ZNPNXGY9')
  const handleStartPhoneTypingGoal = useGoal('AVKOURQD')
  const onSubmit = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    if (!contactEmail) {
      return
    }
    let contactType = 'Investor | General Enquiry'

    const postObj = {
      contactEmail,
      contactName,
      contactType,
      mobilePhone,
      message,
      agreedToMailingList,
      hcaptchaToken,
      hcaptchaEkey
    }

    handleGoal()
    try {
      fetch('/api/registerInterest', { method: 'POST', body: JSON.stringify(postObj) }).then((response) => {
        let isOk = response.ok
        if (isOk) {
          setSubmitted(true)
          setError(false)
        } else {
          setError(true)
        }
      })
    } catch (e) {
      setError(true)
    }
  }

  return (
    <Box alignItems="center">
      {submitted ? (
        <Typography variant="body1" className={classes.submitted}>
          Thank you! Your message has been sent.
        </Typography>
      ) : (
        <form className={classes.form}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item sm={12}>
              <TextField
                InputLabelProps={{ classes: { root: classes.textField } }}
                className={classes.textField}
                id="name"
                type="name"
                label="Name"
                onFocus={() => handleTypingNameGoal()}
                required
                onChange={(e) => setContactName(e.target.value)}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                InputLabelProps={{ classes: { root: classes.textField } }}
                className={classes.textField}
                id="email"
                type="email"
                label="E-Mail Address"
                required
                onChange={(e) => setContactEmail(e.target.value)}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                InputLabelProps={{ classes: { root: classes.textField } }}
                className={classes.mobilePhoneField}
                id="contactPhone"
                label="Mobile Phone"
                onFocus={() => handleStartPhoneTypingGoal()}
                onChange={(e) => setMobilePhone(e.target.value)}
              />
              <Typography variant="caption" className={classes.contactNumberFooter} gutterBottom>
                Please include your full country code with your mobile number
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <TextField
                InputLabelProps={{ classes: { root: classes.textField } }}
                className={classes.textField}
                id="message"
                label="Message"
                required
                multiline
                onChange={(e) => setMessage(e.target.value)}
                rows={5}
              />
            </Grid>
            <Grid item sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreedToMailingList}
                    onChange={(event) => setAgreedToMailingList(event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Sign me up to the Zeti mailing list"
              />
            </Grid>
            <Grid item sm={12}>
              <HCaptcha
                sitekey="9d02fc10-0cb0-4aea-9f93-fa2c92d542fd"
                onVerify={(token: string, ekey: string) => { setHcaptchaToken(token); setHcaptchaEkey(ekey) }}
              />
            </Grid>
            <Grid item sm={12}>
              <Button type="submit" onClick={(e) => onSubmit(e)} className={classes.submitButton} color="secondary" variant="contained" disabled={!contactEmail || !hcaptchaToken}>
                Submit
              </Button>
              {error && <Typography variant="subtitle1" color="secondary"><br />Sorry something went wrong. Please check your answers and try again</Typography>}
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  )
}

